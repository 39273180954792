import React, { FunctionComponent } from "react";
import SliderBlock from "../SliderBlock";
import { ImageInterface, Slug } from "../../types/SanityTypes";
import { Container } from "react-bootstrap";

import "swiper/scss";
import "./styles.scss";
export interface ArticleSliderInterface {
  heading?: string;
  bgColor?: {
    title: string;
  };
  content: {
    _id: string;
    _rawHeroImage: ImageInterface;
    slug: {
      current: string;
    };
    headline: string;
    parentSection: Slug & {
      parentSection?: Slug;
    };
    // For Preview
    heroImage?: ImageInterface;
  }[];
  themeColor?: string;
}

const ArticleSlider: FunctionComponent<ArticleSliderInterface> = ({ heading, bgColor, content, themeColor }) => {
  const titleColor = themeColor?.toLowerCase() || bgColor?.title.toLowerCase();

  const colorList: { [key: string]: string } = {
    purple: "var(--purple-light-color)",
    orange: "var(--orange-bright-color)",
    pink: "var(--pink-bright-color)",
    teal: "var(--teal-color)",
    primary: "var(--primary-color)",
    red: "var(--orange-red-color)",
    blue: "var(--blue-dark-color)"
  };

  return (
    <section className="article-slider" data-testid="article-slider">
      <Container fluid>
        {heading && (
          <h2 style={{ color: colorList[titleColor as string] }} data-testid="article-slider-heading">
            {heading}
          </h2>
        )}
        {content && <SliderBlock data={content} textColor={colorList[titleColor as string]} type="article" />}
      </Container>
    </section>
  );
};

export default ArticleSlider;
